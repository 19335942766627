import { useEffect, useState } from 'react'

export const RECENT_PREVIEW_AS_MEMBER = 'RECENT_PREVIEW_AS_MEMBER'
export const RECENT_CANNED_RESPONSES = 'RECENT_CANNED_RESPONSES'
export const RECENT_FIELDS = 'RECENT_FIELDS'
const isServer = typeof window === 'undefined'
export default function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => initialValue)

  const initialize = () => {
    try {
      if (typeof window === 'undefined') return initialValue
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  }

  useEffect(() => {
    if (!isServer) {
      setStoredValue(initialize())
    }
  }, [])

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}
