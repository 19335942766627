import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import createSelectors from '~/hooks/stores/createSelectors'
import type {
  EveryActionPageInfo,
  EveryActionSavedListPageInfo,
  EveryActionStoreType
} from '~/types/everyaction'

export const activistCodesInitialState: EveryActionPageInfo = {
  batchCount: 0,
  committeeId: null,
  data: [],
  errorMessage: '',
  hasMore: false
}

export const savedListsInitialState: EveryActionSavedListPageInfo = {
  batchSize: 100,
  committeeId: null,
  hasMore: true,
  list: [],
  listLength: null,
  loadMore: true,
  offset: 0,
  processedList: []
}

export const onlineActionsInitialState: EveryActionPageInfo = {
  batchCount: 0,
  committeeId: null,
  data: [],
  errorMessage: '',
  hasMore: false
}

export const everyActionInitialState: EveryActionStoreType = {
  activeCommittee: null,
  activistCodes: {
    ...activistCodesInitialState
  },
  committees: [],
  groups: {
    activistCodes: null,
    onlineActions: null,
    savedLists: null
  },
  errorMessage: '',
  loading: {
    activistCodes: false,
    committees: false,
    onlineActions: false,
    savedLists: false
  },
  onlineActions: {
    ...onlineActionsInitialState
  },
  savedLists: {
    ...savedListsInitialState
  }
}

const useEveryActionStoreBase = create<EveryActionStoreType>()(
  immer((set) => ({
    ...everyActionInitialState
  }))
)

const useEveryActionStore = createSelectors(useEveryActionStoreBase)

export default useEveryActionStore
